import React, { useState } from "react";

import styles from "./index.module.scss";

import { Toast } from "../../hooks/useToast";
import { Profile } from "../../Api/Profile";
import ContentContainer from "../../components/ContentContainer";


import { Col, Input, Row } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md"
import { updateProfileImageThunk, updateProfilePasswordThunk } from "../../features/ProfileReducer";
import { updateKey } from "../../features/DefaultReducer";
import { Button, message, Upload } from 'antd';

export default function ChangePassword() {
    const dispatch = useDispatch()
    let { image, firstname } = useSelector(state => state.profile.data.user)
    const [isUploading, setIsUploading] = useState(false);
    const [data, setData] = useState({
        current_password: "",
        new_password: "",
        confirm_password: ""
    });
    const [isSubmitted, setIsSubmitted] = useState(false);

    function handleChange(e) {
        const { name, value } = e.target;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    async function handleSubmit() {
        if (isSubmitted) return;

        const format = /^(?=.*[A-Z])(?=.*[!@#$&*])(?=.*[0-9])(?=.*[a-z]).{8,150}$/;

        const { current_password, new_password, confirm_password } = data;
        if (!current_password || !new_password || !confirm_password) return Toast("Field Should Not Be Empty", 'error', false);
        if (!new_password.match(format)) return Toast('Password should be atleast 8 characters long and contain number, capital letter and special character', 'error', false);
        if (new_password !== confirm_password) return Toast("Confirm Password Is Not Same As New Password", 'error', false);

        setIsSubmitted(true);
        dispatch(updateProfilePasswordThunk({
            data,
            handleClose: () => {
                localStorage.removeItem(process.env.REACT_APP_WEB_STORAGE_TOKEN);
                dispatch(updateKey({ isLoggedIn: false }))
            },
            setIsSubmitted
        }))
    }

    const beforeUpload = (file) => {
        if (isUploading) return false;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG file!', 'error', false);
        }
        else if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        if (!isJpgOrPng || !isLt2M) return false;


        setIsUploading(true)
        const data = new FormData()
        data.append('image', file);
        dispatch(updateProfileImageThunk({ data, setIsUploading }))



    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <div className={styles.changePassword}>
            <div className={styles.imageContainer}>
                <img src={image} alt="User Image" className={styles.userImage} />
                <h1 className={styles.username}>{firstname}</h1>
                <Upload
                    showUploadList={false}
                    beforeUpload={beforeUpload}
                    accept="image/jpeg,image/png,image/png"
                    customRequest={dummyRequest}
                >
                    <button className={styles.upload}>Upload Photo</button>
                </Upload>
            </div>
            <div className={styles.passwordContainer}>
                <Row className={styles.row}>
                    <Col lg={8} xs={24}>
                        <p className={styles.title}>Current Password</p>
                    </Col>
                    <Col lg={16} xs={24}>
                        <Input.Password
                            size="large"
                            autoComplete="off"
                            name="current_password"
                            value={data.current_password}
                            onChange={handleChange}
                            iconRender={(visible) => (visible ? <MdOutlineVisibility /> : <AiOutlineEyeInvisible />)}
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col lg={8} xs={24}>
                        <p className={styles.title}>New Password</p>
                    </Col>
                    <Col lg={16} xs={24}>
                        <Input.Password
                            size="large"
                            autoComplete="off"
                            name="new_password"
                            value={data.new_password}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col lg={8} xs={24}>
                        <p className={styles.title}>Retype Password</p>
                    </Col>
                    <Col lg={16} xs={24}>
                        <Input.Password
                            size="large"
                            autoComplete="off"
                            name="confirm_password"
                            value={data.confirm_password}
                            onChange={handleChange}
                        />
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col lg={8} xs={0}>
                    </Col>
                    <Col lg={16} xs={24}>
                        <button className={styles.submit} onClick={handleSubmit}>
                            Save Changes
                        </button>
                    </Col>
                </Row>
            </div>

        </div >
    )
}