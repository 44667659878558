
import { confirmAlert } from 'react-confirm-alert';
import moment from "moment"
import { authenticateStatusEnum } from '../constants';
import { useDispatch } from 'react-redux';
import { deleteUserThunk, updateUserManagementThunk } from '../features/UserManagementReducer';
import { deleteNotificationThunk } from '../features/NotificationReducer';
import { deleteMenuThunk } from '../features/MenuReducer';

import { ReactComponent as Bin } from "./images/bin.svg"
import { ReactComponent as Edit } from "./images/edit.svg"
import { ReactComponent as Block } from "./images/block.svg"
import { ReactComponent as Unblock } from "./images/unblock.svg"
import DeleteModal from '../components/ModalContainer/DeleteModal';
import { deleteLeaderboardUserThunk, updateLeaderboardUserThunk } from '../features/LeaderboardReducer';


const actionIconStyle = {
    width: "100%",
    margin: '0 auto',
    padding: "3px",
    cursor: "pointer",
    width: "fit-content",
    fontSize: "18px",
    background: "white",
    borderRadius: "100%",
    height: "auto",
    display: "flex"
}

export function submit(title, message, cb, rejectCB = () => { }) {
    confirmAlert({
        title,
        message,
        buttons: [
            {
                label: 'Yes',
                onClick: () => cb()
            },
            {
                label: 'No',
                onClick: () => rejectCB()
            }
        ],
        closeOnEscape: true,
        closeOnClickOutside: true,
        keyCodeForClose: [8, 32],
    });
}

export function submitDelete(title, message, cb, rejectCB = () => { }) {
    confirmAlert({
        customUI: ({ onClose }) => {
            return (
                <DeleteModal
                    text={message}
                    handleClose={onClose}
                    handleDelete={() => {
                        cb();
                        onClose();
                    }}

                />
            );
        },
        overlayClassName: "confirm-alert-background-change"
    });
}


export function useUserManagementHeader() {
    const dispatch = useDispatch();

    return [
        {
            title: 'Profile Picture',
            dataIndex: "image",
            align: "center",
            width: 220,
            render: (val) => {
                return <div>
                    <img src={val} alt="User Image" style={{ width: "35px", height: "35px", borderRadius: "100%" }} />
                </div>
            }
        },
        {
            title: 'First Name',
            dataIndex: 'firstname',
            align: "center",
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
            align: "center",
        },
        {
            title: 'Email',
            dataIndex: 'email',
            align: "center",
        },
        {
            title: 'Phone No',
            dataIndex: 'mobile_no',
            align: "center",
            render: (mobile_no) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{mobile_no || '-'}</p>
            }
        },
        {
            title: "Action",
            align: "Center",
            render: (record) => {
                const status = record.is_blocked ? 'unblock' : 'block';
                return <div style={{ width: "100%", border: "none", textAlign: "center", padding: "3px 15px", display: "flex", alignItems: "center" }}>
                    <a
                        style={{ ...actionIconStyle, margin: "0 3px 0 auto" }}
                        onClick={() =>
                            submitDelete(
                                "Change Status", `Are you sure you want to ${status} this user?`,
                                () => dispatch(updateUserManagementThunk(record.id))
                            )
                        }
                    >
                        {record.is_blocked ? <Block /> : <Unblock />}
                    </a>
                    <a
                        style={{ ...actionIconStyle, margin: "0 auto 0 3px" }}
                        onClick={() => submitDelete(
                            "Change Status", `Are you sure you want to delete this user?`,
                            () => dispatch(deleteUserThunk(record.id))
                        )}
                    >
                        <Bin />
                    </a>
                </div >
            },
        }
    ];
}

export function useLeaderboardHeader() {
    const dispatch = useDispatch()
    return [
        {
            title: 'Profile Picture',
            dataIndex: "image",
            align: "center",
            width: 220,
            render: (val) => {
                return <div>
                    <img src={val} alt="User Image" style={{ width: "35px", height: "35px", borderRadius: "100%" }} />
                </div>
            }
        },
        {
            title: 'First Name',
            dataIndex: 'firstname',
            align: "center"
        },
        {
            title: 'Last Name',
            dataIndex: 'lastname',
            align: "center"
        },
        {
            title: 'Game Category',
            dataIndex: 'game',
            align: "center"
        },
        {
            title: 'Date',
            dataIndex: 'date',
            align: "center"
        },
        {
            title: 'Time Interval',
            dataIndex: 'duration',
            align: "center"
        },
        {
            title: 'Position',
            dataIndex: 'position',
            align: "center"
        },
        {
            title: "Action",
            align: "Center",
            render: (record) => {
                const status = record.is_blocked ? 'unblock' : 'block';
                return <div style={{ width: "100%", border: "none", textAlign: "center", padding: "3px 15px", display: "flex", alignItems: "center" }}>
                    <a
                        style={{ ...actionIconStyle, margin: "0 3px 0 auto" }}
                        onClick={() =>
                            submitDelete(
                                "Change Status", `Are you sure you want to ${status} this user?`,
                                () => dispatch(updateLeaderboardUserThunk(record.user_id))
                            )
                        }
                    >
                        {record.is_blocked ? <Block /> : <Unblock />}
                    </a>
                    <a
                        style={{ ...actionIconStyle, margin: "0 auto 0 3px" }}
                        onClick={() => submitDelete(
                            "Change Status", `Are you sure you want to delete this user?`,
                            () => dispatch(deleteLeaderboardUserThunk(record.user_id))
                        )}
                    >
                        <Bin />
                    </a>
                </div >
            },
        }
    ];
}


export function useGameManagementHeader(gameType, gameDifficulty, setData, deletePoint) {
    const dispatch = useDispatch()
    switch (gameType) {
        case 'game1':
            return [
                {
                    title: 'Point Name',
                    dataIndex: 'name'
                },
                {
                    title: 'Latitude',
                    dataIndex: 'lat'
                },
                {
                    title: 'Longitude',
                    dataIndex: 'lng'
                },
                {
                    title: "Action",
                    align: "Center",
                    render: (record) => {
                        return <div style={{ width: "100%", border: "none", textAlign: "center", padding: "3px 15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p
                                style={{ ...actionIconStyle, margin: "0 3px 0 auto" }}
                                onClick={() => setData({
                                    point_name: record.name,
                                    lat: record.lat,
                                    lng: record.lng,
                                })}
                            >
                                <Edit />
                            </p>
                            <p
                                style={{ ...actionIconStyle, margin: "0 auto 0 3px" }}
                                onClick={() => submitDelete(
                                    "Change Status", `Are you sure you want to delete this point?`,
                                    () => dispatch(deletePoint({ point_name: record.name, gameType, gameDifficulty }))
                                )}
                            >
                                <Bin />
                            </p>
                        </div>
                    },
                },
            ];
        case 'game2':
            return [
                {
                    title: 'Point Name',
                    dataIndex: 'name'
                },
                {
                    title: 'Latitude',
                    dataIndex: 'lat'
                },
                {
                    title: 'Longitude',
                    dataIndex: 'lng'
                },
                {
                    title: "Action",
                    align: "Center",
                    render: (record) => {
                        return <div style={{ width: "100%", border: "none", textAlign: "center", padding: "3px 15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p
                                style={{ ...actionIconStyle, margin: "0 3px 0 auto" }}
                                onClick={() => setData({
                                    point_name: record.name,
                                    lat: record.lat,
                                    lng: record.lng,
                                })}
                            >
                                <Edit />
                            </p>
                            <p
                                style={{ ...actionIconStyle, margin: "0 auto 0 3px" }}
                                onClick={() => submitDelete(
                                    "Change Status", `Are you sure you want to delete this point?`,
                                    () => dispatch(deletePoint({ point_name: record.name, gameType, gameDifficulty }))
                                )}
                            >
                                <Bin />
                            </p>
                        </div>
                    },
                },
            ];
        case 'game3':
            return [
                {
                    title: 'Point Name',
                    dataIndex: 'name'
                },
                {
                    title: 'Latitude',
                    dataIndex: 'lat'
                },
                {
                    title: 'Longitude',
                    dataIndex: 'lng'
                },
                {
                    title: 'Clue',
                    dataIndex: 'clue'
                },
                {
                    title: "Action",
                    align: "Center",
                    render: (record) => {
                        return <div style={{ width: "100%", border: "none", textAlign: "center", padding: "3px 15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                            <p
                                style={{ ...actionIconStyle, margin: "0 3px 0 auto" }}
                                onClick={() => setData({
                                    point_name: record.name,
                                    lat: record.lat,
                                    lng: record.lng,
                                })}
                            >
                                <Edit />
                            </p>
                            <p
                                style={{ ...actionIconStyle, margin: "0 auto 0 3px" }}
                                onClick={() => submitDelete(
                                    "Change Status", `Are you sure you want to delete this point?`,
                                    () => dispatch(deletePoint({ point_name: record.name, gameType, gameDifficulty }))
                                )}
                            >
                                <Bin />
                            </p>
                        </div>
                    },
                },
            ];
    }


}


export function useMenuHeader(handleUpdateMenu) {
    const dispatch = useDispatch()
    return [
        // {
        //     title: 'Item Image',
        //     dataIndex: "item_image",
        //     align: "center",
        //     width: 220,
        //     render: (val) => {
        //         return <div>
        //             <img src={val} alt="User Image" style={{ width: "35px", height: "35px", borderRadius: "100%" }} />
        //         </div>
        //     }
        // },
        {
            title: 'Item Name',
            dataIndex: "item_name",
            align: "center"
        },
        {
            title: 'Item Price',
            dataIndex: 'price',
            align: "center",
            render: (price) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{`$${price}`}</p>
            }
        },
        {
            title: 'Category',
            dataIndex: 'category_name',
            align: "center",
            render: (category_name) => {
                return <p style={{ margin: 0, textAlign: "center" }}>{category_name}</p>
            }
        },
        {
            title: "Action",
            align: "Center",
            render: (record) => {
                return <div style={{ width: "100%", border: "none", textAlign: "center", padding: "3px 15px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <p
                        style={{ ...actionIconStyle, margin: "0 3px 0 auto" }}
                        onClick={() => handleUpdateMenu(record)}
                    >
                        <Edit />
                    </p>
                    <p
                        style={{ ...actionIconStyle, margin: "0 auto 0 3px" }}
                        onClick={() => submitDelete(
                            "Change Status", `Are you sure you want to delete this menu?`,
                            () => dispatch(deleteMenuThunk(record.id))
                        )}
                    >
                        <Bin />
                    </p>
                </div>
            },
        },

    ];
}


export function useNotificationHeader() {
    const dispatch = useDispatch();
    return [
        {
            title: 'Subject',
            align: 'center',
            dataIndex: 'body',
            render: (data) => {
                return <p style={{
                    margin: 0, textAlign: 'left', WebkitBoxOrient: "vertical", WebkitLineClamp: 1, overflow: "hidden",
                    display: "-webkit-box",
                    textOverflow: "ellipsis"
                }}>{data}</p>
            }
        },
        {
            title: 'Date',
            dataIndex: 'createdAt',
            align: "center",
            width: 230,
            render: (date) => {
                return <p style={{ margin: 0, textAlign: "center", whiteSpace: 'nowrap' }}>{moment(date).format('MMM DD, YYYY')}</p>
            }
        },
        {
            title: "Action",
            align: "Center",
            width: 230,
            render: (record) => {
                return <div style={{ width: "100%", border: "none", textAlign: "center", padding: "3px 15px", display: "flex", alignItems: "center" }}>
                    <p
                        style={{ width: "100%", margin: '0 auto', cursor: "pointer", width: "fit-content" }}
                        onClick={() => submitDelete(
                            "Change Status", `Are you sure you want to delete this notification?`,
                            () => dispatch(deleteNotificationThunk(record.id))
                        )}
                    >
                        <Bin />
                    </p>
                </div>
            },
        }
    ];
}




