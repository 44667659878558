import React, { useCallback, useEffect, useState } from "react";

/**Import Styles and Images */
import styles from "./index.module.scss"

import TableLayout from "../../components/TableLayout";
import { useNotificationHeader } from "../../schemas/tableHeaderSchema";

import { useSelector, useDispatch } from "react-redux";
import { resetValue } from "../../features/SearchReducer";
import ContentContainer from "../../components/ContentContainer";
import { createNotificationThunk, fetchNotificationThunk } from "../../features/NotificationReducer.js";
import ModalContainer from "../../components/ModalContainer";
import { Input } from "antd";
import _ from 'lodash';
import { Toast } from "../../hooks/useToast.js";
import SearchBar from "../../components/CustomizeComponents/SearchBar.js";

import { useNavigate } from "react-router-dom";
import { ReactComponent as DashboardIcon } from "./images/dashboard.svg";
import { GrNext } from "react-icons/gr"
import { AiOutlinePlus } from "react-icons/ai"
import { RxCross2 } from "react-icons/rx"
import { Excel } from "antd-table-saveas-excel";
import moment from 'moment'

export default function Notification() {
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');

    const navigate = useNavigate()
    const NOTIFICATION_INTTIAL = {
        isModal: false,
        data: {
            title: "",
            body: "",
        }
    }
    const [notification, setNotification] = useState(NOTIFICATION_INTTIAL);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const { data, status } = useSelector(state => state.notification);
    const [list, setList] = useState(data);
    const dispatch = useDispatch();
    const header = useNotificationHeader()

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchNotificationThunk());
        }

        return () => dispatch(resetValue())
    }, [])

    useEffect(() => {
        if (!search) return setList(data);


        const array = [
            ...data.map(item => {
                if (item.body.toLowerCase().includes(search.toLowerCase())) {
                    return item
                }
            })
        ].filter(Boolean)
        setList(array);

    }, [data, search])



    const handleInputChange = (e) => {
        const { name, value } = e.target
        setNotification((prev) => {
            return {
                ...prev,
                data: {
                    ...prev.data,
                    [name]: value
                }
            }
        })
    }
    const handleSubmitForm = () => {
        if (isSubmitted) return;

        const form = notification.data;
        if (!form.title) return Toast('Title should not be empty', 'error', false);
        if (!form.body) return Toast('Message should not be empty', 'error', false);

        setIsSubmitted(true);
        dispatch(createNotificationThunk({ data: form, handleModalClose, setIsSubmitted }));
    }

    const handleModalClose = useCallback(() => {
        setNotification(NOTIFICATION_INTTIAL);
    }, [])

    const handleOpenModal = useCallback(() => {
        setNotification(prev => {
            return {
                ...prev,
                isModal: true
            }
        });
    }, [])

    const handleSaveExcel = () => {
        const excel = new Excel();
        excel
            .addSheet("Notifications")
            .addColumns([
                {
                    title: "Subject",
                    dataIndex: "body",
                    key: "message"
                },
                {
                    title: "Date",
                    dataIndex: "createdAt",
                    key: "createdAt"
                },
            ])
            .addDataSource(list.map(item => {
                return {
                    body: item.body,
                    createdAt: moment(item.createdAt).format('MMM DD, YYYY'),
                }
            }), {
                str2Percent: true
            })
            .saveAs("Notifications.xlsx");
    }

    return (
        <div className={styles.contentLayout}>
            <div className={styles.titleContainer}>
                <div className={styles.headerContainer}>
                    <h1 className={styles.title}>Notification / Announcement</h1>
                    {/* <div className={styles.verticalDivider}></div> */}
                    <div className={styles.endpointContainer}>
                        <div
                            className={styles.iconContainer}
                            onClick={() => navigate('/dashboard')}
                        >
                            <DashboardIcon />
                        </div>
                        <span className={styles.next}><GrNext /></span>
                        <h2 className={styles.endpoint}>Notification / Announcement</h2>
                    </div>
                    <div className={styles.addButtonContainer}>
                        <button className={styles.button} onClick={handleOpenModal}>
                            Create<span>< AiOutlinePlus /></span>
                        </button>
                    </div>
                </div>
                <hr className={styles.divider} />

            </div>
            <ContentContainer
                hasOptions={false}
            >
                <SearchBar hasDivider={false} value={search} onChange={(value) => setSearch(value)} />
                {notification.isModal ? <ModalContainer handleClose={handleModalClose}>
                    <div className={styles.modal}>
                        <div className={styles.headerContainer}>
                            <h1 className={styles.title}>New Notification</h1>
                            <span onClick={handleModalClose}><RxCross2 /></span>
                        </div>
                        <hr />
                        <div className={styles.fieldsContainer}>
                            <Input
                                size="large"
                                placeholder="Enter Title"
                                className={styles.field}
                                name='title'
                                value={notification.data.title}
                                onChange={handleInputChange}
                            />
                            <Input.TextArea
                                rows={4}
                                size="large"
                                placeholder="Enter Message"
                                className={styles.field}
                                name='body'
                                value={notification.data.body}
                                onChange={handleInputChange}
                            />
                            <button className={styles.submit} onClick={handleSubmitForm}>
                                Send
                            </button>
                        </div>
                    </div>
                </ModalContainer> : ""
                }
                <div className={styles.menu}>
                    <TableLayout
                        isLoading={(status == 'pending')}
                        data={list}
                        headers={header}
                        pageNo={currentPage}
                        handlePageChange={(pageNo) => setCurrentPage(pageNo)}
                    />
                    {(status === 'success') ? <div className={styles.toolContainer}>
                        <button onClick={handleSaveExcel} className={styles.excel}>Excel</button>

                        <p className={styles.recordCount}>
                            {`Showing ${(currentPage - 1) * 8 + 1} to ${(currentPage - 1) * 8 + 8} of ${list.length} entries`}
                        </p>
                    </div> : ""}

                </div>
            </ContentContainer >
        </div>
    )
}