import React, { useCallback, useEffect, useMemo, useState } from "react";

import styles from "./index.module.scss"

import { useGameManagementHeader } from "../../schemas/tableHeaderSchema";
import { addOrUpdatePointThunk, deletePointThunk, fetchGamesThunk, updateStartOrEndPointThunk } from "../../features/GameReducer";
import TableLayout from "../../components/TableLayout";
import ContentContainer from "../../components/ContentContainer";
import ModalContainer from "../../components/ModalContainer";

import { AiOutlinePlus } from "react-icons/ai"
import { Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _, { update } from 'lodash'
import { RxCross2 } from "react-icons/rx"
import { Toast } from "../../hooks/useToast.js";
import Tabs from "../../components/CustomizeComponents/Tabs.js";


export default function GameManagement() {
    const dispatch = useDispatch();
    const MODAL_INITIAL = {
        point_name: '',
        lat: undefined,
        lng: undefined,
        clue: ''
    }
    const options = [
        { key: 'game1', label: 'Wild Checkpoints' },
        { key: 'game2', label: 'Adventure Race' },
        { key: 'game3', label: 'Wild Hunt' },
    ]
    const difficulty_options = [
        { key: 'small', label: 'Small' },
        { key: 'medium', label: 'Medium' },
        { key: 'big', label: 'Big' },
    ]
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isModal, setIsModal] = useState(false);
    const [isUpdateModal, setIsUpdateModal] = useState(false);
    const [modalData, setModalData] = useState(MODAL_INITIAL);
    const [updateModalData, setUpdateModalData] = useState(MODAL_INITIAL);
    const [gameType, setGameType] = useState('game1');
    const [gameDifficulty, setGameDifficulty] = useState('small');
    const { data: gameData, status } = useSelector(state => state.game)
    const [startPoint, setStartPoint] = useState({
        lat: undefined,
        lng: undefined
    })
    const [endPoint, setEndPoint] = useState({
        lat: undefined,
        lng: undefined
    })
    const header = useGameManagementHeader(gameType, gameDifficulty, handleUpdateModalOpen, deletePointThunk);

    const data = useMemo(() => {
        if (_.isEmpty(gameData)) return []
        if (gameType == 'game2') return gameData[gameType].raceTypes[gameDifficulty]
        return gameData[gameType]
    }, [gameType, gameData, gameDifficulty])

    const isStartPointSubmit = useMemo(() => {
        if (_.isEmpty(gameData)) return false;
        if (gameType !== 'game2') return false;
        return ((parseFloat(startPoint.lat) != parseFloat(gameData[gameType].raceTypes[gameDifficulty].startPoint.lat)) || (parseFloat(startPoint.lng) != parseFloat(gameData[gameType].raceTypes[gameDifficulty].startPoint.lng)))
    }, [startPoint.lat, startPoint.lng, gameData, gameDifficulty])

    const isEndPointSubmit = useMemo(() => {
        if (_.isEmpty(gameData)) return false;
        if (gameType !== 'game2') return;
        return ((parseFloat(endPoint.lat) != parseFloat(gameData[gameType].raceTypes[gameDifficulty].endPoint.lat)) || (parseFloat(endPoint.lng) != parseFloat(gameData[gameType].raceTypes[gameDifficulty].endPoint.lng)))
    }, [endPoint.lat, endPoint.lng, gameData, gameDifficulty])


    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchGamesThunk());
        }
        // return () => dispatch(resetValue())
    }, [])


    useEffect(() => {
        if (!_.isEmpty(gameData)) {
            setStartPoint(prev => {
                if (isNaN(prev.lat) || isNaN(prev.lng)) {
                    return gameData['game2'].raceTypes[gameDifficulty].startPoint
                }
                return prev
            })
            setEndPoint(prev => {
                if (isNaN(prev.lat) || isNaN(prev.lng)) {
                    return gameData['game2'].raceTypes[gameDifficulty].endPoint
                }
                return prev
            })
        }
    }, [gameData])

    // useEffect(() => {
    // if (!_.isEmpty(gameData)) {
    //     setStartPoint(gameData['game2'].raceTypes[gameDifficulty].startPoint)
    //     setEndPoint(gameData['game2'].raceTypes[gameDifficulty].endPoint)
    // }
    // }, [gameDifficulty])



    const handleOpenModal = useCallback(() => {
        setIsModal(true)
    }, [])
    const handleModalClose = useCallback(() => {
        setIsModal(false)
        setModalData(MODAL_INITIAL)
    }, [])

    const handleUpdateModalClose = useCallback(() => {
        setIsUpdateModal(false)
        setUpdateModalData(MODAL_INITIAL)
    }, [])

    function handleUpdateModalOpen(data) {
        setIsUpdateModal(true)
        setUpdateModalData(data)
    }

    const handleGameTypeChange = useCallback((value) => {
        setGameType(value)
    }, [])

    const handleGameDifficultyChange = (value) => {
        setGameDifficulty(value)
        if (!_.isEmpty(gameData)) {
            setStartPoint(gameData['game2'].raceTypes[value].startPoint)
            setEndPoint(gameData['game2'].raceTypes[value].endPoint)
        }
    }

    const handleInputChange = (name, value) => {
        setModalData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }
    const handleUpdateInputChange = (name, value) => {
        setUpdateModalData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleStartOrEndPointChange = (name, value, func) => {
        func(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    const handleStartOrEndPointSave = (name, value) => {
        if (isSubmitted) return;
        if (isNaN(parseFloat(value.lat))) return Toast('Latitude should not be empty', 'error', false)
        if (isNaN(parseFloat(value.lng))) return Toast('Longitude should not be empty', 'error', false)

        setIsSubmitted(true)
        let data = {};
        data[name] = value;
        dispatch(updateStartOrEndPointThunk({ data, gameDifficulty, setIsSubmitted }))
    }

    const handleAddPoint = () => {
        if (isSubmitted) return;

        if (!modalData.point_name) return Toast('Point name should not be empty', 'error', false)
        if (data.points.some(item => item.name.trim() == modalData.point_name.trim())) return Toast('Point name already exists', 'error', false)
        if (isNaN(parseFloat(modalData.lat))) return Toast('Latitude should not be empty', 'error', false)
        if (isNaN(parseFloat(modalData.lng))) return Toast('Longitude should not be empty', 'error', false)
        if ((gameType == 'game3') && !modalData.clue) return Toast('Clue should not be empty', 'error', false)

        setIsSubmitted(true);
        dispatch(addOrUpdatePointThunk({ data: modalData, gameType, gameDifficulty, setIsSubmitted, handleModalClose }))
    }

    const handleUpdatePoint = () => {
        if (isSubmitted) return;

        if (!updateModalData.point_name) return Toast('Point name should not be empty', 'error', false)
        if (isNaN(parseFloat(updateModalData.lat))) return Toast('Latitude should not be empty', 'error', false)
        if (isNaN(parseFloat(updateModalData.lng))) return Toast('Longitude should not be empty', 'error', false)
        if ((gameType == 'game3') && !updateModalData.clue) return Toast('Clue should not be empty', 'error', false)

        setIsSubmitted(true);
        dispatch(addOrUpdatePointThunk({ data: updateModalData, gameType, gameDifficulty, setIsSubmitted, handleModalClose: handleUpdateModalClose }))
    }
    return (
        <ContentContainer>
            {isModal ? <ModalContainer handleClose={handleModalClose}>
                <div className={styles.modal}>
                    <div className={styles.headerContainer}>
                        <h1 className={styles.title}>Add Points</h1>
                        <span onClick={handleModalClose}><RxCross2 /></span>
                    </div>
                    <hr />
                    {/* <p className={styles.note}><span>*</span>Enter existed point name to update point or add unique name to add point</p> */}

                    <div className={styles.fieldsContainer}>
                        <Input
                            size="large"
                            placeholder="Enter Point Name"
                            className={styles.field}
                            name='point_name'
                            value={modalData.point_name}
                            onChange={(e) => handleInputChange('point_name', e.target.value)}
                        />
                        <Input
                            size="large"
                            type="number"
                            placeholder="Enter Point Latitude"
                            className={styles.field}
                            name='lat'
                            value={modalData.lat}
                            onChange={(e) => handleInputChange('lat', e.target.value)}
                        />
                        <Input
                            size="large"
                            type="number"
                            placeholder="Enter Point Longitude"
                            className={styles.field}
                            name='lng'
                            value={modalData.lng}
                            onChange={(e) => handleInputChange('lng', e.target.value)}
                        />
                        {(gameType == 'game3') ? <Input
                            size="large"
                            placeholder="Enter Point Clue Text"
                            className={styles.field}
                            name='clue'
                            value={modalData.clue}
                            onChange={(e) => handleInputChange('clue', e.target.value)}
                        /> : ""}
                        <button className={styles.submit} onClick={handleAddPoint}>
                            Send
                        </button>
                    </div>
                </div>
            </ModalContainer> : ""
            }
            {isUpdateModal ? <ModalContainer handleClose={handleUpdateModalClose}>
                <div className={styles.modal}>
                    <div className={styles.headerContainer}>
                        <h1 className={styles.title}>{`Update Points : ${updateModalData.point_name} `}</h1>
                        <span onClick={handleUpdateModalClose}><RxCross2 /></span>
                    </div>
                    <hr />

                    <div className={styles.fieldsContainer}>
                        <Input
                            size="large"
                            type="number"
                            placeholder="Enter Point Latitude"
                            className={styles.field}
                            name='lat'
                            value={updateModalData.lat}
                            onChange={(e) => handleUpdateInputChange('lat', e.target.value)}
                        />
                        <Input
                            size="large"
                            type="number"
                            placeholder="Enter Point Longitude"
                            className={styles.field}
                            name='lng'
                            value={updateModalData.lng}
                            onChange={(e) => handleUpdateInputChange('lng', e.target.value)}
                        />
                        {(gameType == 'game3') ? <Input
                            size="large"
                            placeholder="Enter Point Clue Text"
                            className={styles.field}
                            name='clue'
                            value={updateModalData.clue}
                            onChange={(e) => handleUpdateInputChange('clue', e.target.value)}
                        /> : ""}
                        <button className={styles.submit} onClick={handleUpdatePoint}>
                            Save
                        </button>
                    </div>
                </div>
            </ModalContainer> : ""
            }
            <div className={styles.game}>
                <div className={styles.selectContainer}>
                    {(gameType == 'game2') ?
                        <Tabs
                            current={gameDifficulty}
                            onChange={handleGameDifficultyChange}
                            tabs={difficulty_options}
                            align="left"
                        /> : ''}
                    <Tabs
                        current={gameType}
                        onChange={handleGameTypeChange}
                        tabs={options}
                        align="right"
                    />
                </div>
                {(gameType == 'game2') ?
                    <div className={styles.infoContainer}>
                        <div className={styles.pointContainer}>
                            <h3 className={styles.title}>Start Point</h3>
                            <div className={styles.inputContainer}>
                                <div
                                    className={styles.input}
                                >
                                    <Input
                                        type="number"
                                        value={startPoint?.lat}
                                        onChange={(e) => handleStartOrEndPointChange('lat', e.target.value, setStartPoint)}
                                    />
                                    <p className={styles.description}>Latitude</p>
                                </div>
                                <div
                                    className={styles.input}
                                >
                                    <Input
                                        type="number"
                                        value={startPoint?.lng}
                                        onChange={(e) => handleStartOrEndPointChange('lng', e.target.value, setStartPoint)}
                                    />
                                    <p className={styles.description}>Longitude</p>
                                </div>
                            </div>
                            {isStartPointSubmit ? <button className={styles.savePoint} onClick={() => handleStartOrEndPointSave('startPoint', startPoint)}>Save</button> : ""}
                        </div>
                        <div className={styles.pointContainer}>
                            <h3 className={styles.title}>End Point</h3>
                            <div className={styles.inputContainer}>
                                <div
                                    className={styles.input}
                                >
                                    <Input
                                        type="number"
                                        value={endPoint?.lat}
                                        onChange={(e) => handleStartOrEndPointChange('lat', e.target.value, setEndPoint)}
                                    />
                                    <p className={styles.description}>Latitude</p>
                                </div>
                                <div
                                    className={styles.input}
                                >
                                    <Input
                                        type="number"
                                        value={endPoint?.lng}
                                        onChange={(e) => handleStartOrEndPointChange('lng', e.target.value, setEndPoint)}
                                    />
                                    <p className={styles.description}>Latitude</p>
                                </div>
                            </div>
                            {isEndPointSubmit ?
                                <button
                                    className={styles.savePoint}
                                    onClick={() => handleStartOrEndPointSave('endPoint', endPoint)}
                                >Save</button>
                                : ""}
                        </div>
                        <div>
                        </div>

                    </div>
                    : ""}
                <div className={styles.addButtonContainer}>
                    <button className={styles.button} onClick={handleOpenModal}>
                        <span><AiOutlinePlus /></span>Add Points
                    </button>
                </div>
                <TableLayout headers={header} data={data.points} status={'success'} isLoading={(status == 'pending')} />
            </div>
        </ContentContainer>
    )
}