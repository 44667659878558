import React, { useState } from "react";

/**Import Styles and Assets  */
import styles from "./Login.module.scss";

import { Toast } from "../../hooks/useToast";

import { useDispatch, useSelector } from "react-redux";
import { postForgetpasswordThunk, postProfileThunk } from "../../features/ProfileReducer";
import { Checkbox, Input } from 'antd';
import OtpInput from 'react-otp-input';
import { otpVerified } from "../../features/DefaultReducer";


export default function Login() {

    const dispatch = useDispatch();

    const { data } = useSelector(state => state.profile)
    const [remember_me, setRememberMe] = useState(false);
    const [loginForm, setLoginForm] = useState(true);
    const [otpForm, setOtpForm] = useState(false);
    const [loginData, setLoginData] = useState({
        email: "",
        password: "",
    })
    const [sendMail, setSendMail] = useState("");
    const [otp, setOtp] = useState('');

    const formLayout = {
        name: "",
        message: "Login to continue using your account",
        elements: [
            <>
                <p className={styles.label}>Email</p>
                <Input key="1" size="large" type="text" placeholder="Email" autoComplete="off" name="email" value={loginData.email} onChange={handleLoginDataChange} className={styles.input} />
            </>,
            <>
                <p className={styles.label}>Password</p>
                <Input.Password size="large" placeholder="Password" autoComplete="off" name="password" value={loginData.password} onChange={handleLoginDataChange} className={styles.input} />
            </>,
            <div className={styles.checkboxField} key={3}>
                <Checkbox onChange={() => setRememberMe(prev => !prev)} value={remember_me}>Remember Me</Checkbox>
                <p className={styles.forgot} onClick={() => setLoginForm(!loginForm)}>Forgot Password ?</p>
            </div>
        ],
        submitButton: <button className={styles.submit} onClick={handleSubmit}>Login</button>,
    }
    const otpLayout = {
        name: "Enter your verification code",
        message: "We’ve sent an invite to your registered email.",
        elements: [
            <OtpInput
                value={otp}
                onChange={setOtp}
                numInputs={6}
                renderSeparator={<span>-</span>}
                renderInput={(props) => <input {...props} />}
                inputStyle={{ width: "50px", height: "50px", borderRadius: "10px", color: "black", border: "1px solid #ecbf04", margin: "10px 0" }}
            />,
        ],
        submitButton: <button className={styles.submit} onClick={handleVerifyOtp}>Verify</button>,
    }

    const sendMailLayout = {
        name: "Forgot Password",
        message: "Enter your registered email ID to reset the password",
        elements: [
            <>
                <p className={styles.label}>Email</p>
                <Input key="1" size="large" type="text" placeholder="Email" autoComplete="off" name="sendMail" value={sendMail} onChange={(e) => setSendMail(e.target.value)} className={styles.input} />
            </>
        ],
        submitButton: <button className={styles.submit} onClick={handleSubmit}>Send</button>,
    }



    function handleLoginDataChange(e) {
        const { name, value } = e.target;
        setLoginData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })

    }

    function ValidateEmail(value) {

        var validRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        return value.match(validRegex) ? true : false;

    }


    async function handleSubmit() {
        if (loginForm) {
            if (!loginData.email || !loginData.password) {
                Toast('Fields should not be empty', 'error', false);

            }
            else if (!ValidateEmail(loginData.email)) {
                Toast('Invalid Email', 'error', false);
            }
            else {
                dispatch(postProfileThunk({ data: loginData, setOtpForm }));
            }
        }
        else {

            if (!sendMail) {
                Toast('Field should not be empty', 'error', false);

            }
            else if (!ValidateEmail(sendMail)) {
                Toast('Invalid Email', 'error', false);
            }
            else {
                dispatch(postForgetpasswordThunk({ email: sendMail, setLoginForm }));
            }

        }
    }

    function handleVerifyOtp() {
        if (data?.otp !== otp) return Toast('Invalid otp', 'error', false)

        // Toast('Otp verified', 'success', false);
        dispatch(otpVerified({ data, remember_me }))
    }

    return (
        <div className={styles.main}>
            <div className={styles.coverImageContainer}>
                <div className={styles.imageContainer}>
                    <img src={(loginForm && !otpForm) ? "/login-cover.png" : (loginForm && otpForm) ? "/otp-cover.png" : "/forgot-password-cover.png "} alt="Cover Image" />
                </div>
            </div>
            <div className={styles.layout}>
                <div className={`${styles.content} ${!loginForm ? styles.small : ""}`}>
                    <img draggable="false" src={loginForm ? `/logo.png` : "/form-forgot.png"} alt="Logo" />
                    {loginForm ? otpForm ?
                        <LoginForm formData={otpLayout} /> :
                        <LoginForm formData={formLayout} /> :
                        <LoginForm formData={sendMailLayout} />
                    }
                    {loginForm ? "" :
                        <p className={styles.switch} onClick={() => {
                            if (otpForm && loginForm) {
                                setOtpForm(false)
                            }
                            else {
                                setLoginForm(!loginForm)
                            }

                        }}>Return to login</p>
                    }
                </div>
            </div>
        </div>
    )
}

export function LoginForm({ formData }) {

    const { name, message, elements, submitButton } = formData
    return (
        <div className={styles.form}>
            {name ? <h2>{name}</h2> : ""}
            <p>{message}</p>

            <div className={styles.fields}>
                {elements.map(item => {
                    return item;
                })}
            </div>
            {submitButton}

        </div >
    )
}

