import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';


import { ToastContainer } from 'react-toastify';

import { Provider } from 'react-redux'
import { store } from './store.js';

import 'react-quill/dist/quill.snow.css';


import "react-toastify/dist/ReactToastify.css";
import 'react-confirm-alert/src/react-confirm-alert.css';


// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';

// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';


import {
  QueryClient,
  QueryClientProvider,
} from 'react-query'
// Create a client
const queryClient = new QueryClient()



const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <QueryClientProvider client={queryClient}>
    <Provider store={store}>
      <ToastContainer />
      <App />
    </Provider>
  </QueryClientProvider>
);

