import { createSlice } from '@reduxjs/toolkit'
import CryptoJS from 'crypto-js';

const initialState = {
    isLoggedIn: false,
}

export const defaultSlice = createSlice({
    name: 'default',
    initialState,
    reducers: {
        updateKey: (state, actions) => {
            return {
                ...state,
                ...actions.payload

            }
        },
        otpVerified: (state, actions) => {
            if (actions.payload?.remember_me) {
                const encrypted = CryptoJS.AES
                    .encrypt(JSON.stringify(actions.payload?.data), process.env.REACT_APP_SECRET_KEY).toString();
                localStorage.setItem(process.env.REACT_APP_WEB_STORAGE_TOKEN, encrypted);
            }
            return {
                ...state,
                isLoggedIn: true
            }
        }
    },
})

// Action creators are generated for each case reducer function
export const { updateKey, otpVerified } = defaultSlice.actions

export default defaultSlice.reducer