import React from "react";

/**Import Styles and Assets */
import styles from "./ContentLayout.module.scss";
import { ReactComponent as DashboardIcon } from "./images/dashboard.svg";
import { GrNext } from "react-icons/gr"
import { useNavigate } from "react-router-dom";

export default function ContentLayout({ title, children }) {
    const navigate = useNavigate();
    return (
        <div className={styles.contentLayout}>
            {!title ? "" :
                <div className={styles.titleContainer}>
                    <div className={styles.headerContainer}>
                        <h1 className={styles.title}>{title}</h1>
                        {/* <div className={styles.verticalDivider}></div> */}
                        <div className={styles.endpointContainer}>
                            <div
                                className={styles.iconContainer}
                                onClick={() => navigate('/dashboard')}
                            >
                                <DashboardIcon />
                            </div>
                            <span className={styles.next}><GrNext /></span>
                            <h2 className={styles.endpoint}>{title}</h2>
                        </div>
                    </div>
                    <hr className={styles.divider} />

                </div>
            }
            {children}
        </div>
    )
}