import React from "react";
import ReactDom from "react-dom";

/**Import Styles  */
import styles from "./DeleteModal.module.scss";

/**Import Components */
import { RxCross2 } from "react-icons/rx";

export default function DeleteModal({ text = 'Are you sure you want to delete?', handleClose, handleDelete }) {
    return ReactDom.createPortal(
        <div className={styles.modal} >
            <div className={styles.outside} onClick={handleClose}></div>

            <div className={styles.content}>
                <div className={styles.headerContainer}>
                    <h1 className={styles.title}>Confirmation Alert!</h1>
                    <div className={styles.cross} onClick={handleClose}>
                        <RxCross2 />
                    </div>
                </div>
                <hr />
                <h1 className={styles.header}>{text}</h1>
                <hr />
                <div className={`${styles.buttonContainer}`}>
                    <button className={styles.edit} onClick={handleClose}>
                        No
                    </button>
                    <button
                        onClick={handleDelete}
                        className={`${styles.delete} ${styles.deleted}`}
                    >
                        Yes
                    </button>
                </div>

            </div>
        </div>,
        document.getElementById('portal')
    )
}