import React, { useCallback, useState } from "react";
import { Link } from "react-router-dom"

/**Import Assets */
import styles from "./ProtectedLayout.module.scss";

/**Import Custom Component */
import Navbar from "./Navbar";
import { updateKey } from "../../features/DefaultReducer";
import { resetState as profileReset } from "../../features/ProfileReducer";
// import { resetState as textReset } from "../../features/StaticTextReducer";
// import { resetState as userReset } from "../../features/UserManagementReducer";
// import { resetState as dashboardReset } from "../../features/DashboardReducer";
import { resetValue as searchReset } from "../../features/SearchReducer";

import { confirmAlert } from 'react-confirm-alert';
import { useDispatch } from "react-redux";

import { GiHamburgerMenu } from "react-icons/gi";


import { AiOutlineHome } from "react-icons/ai";
import { BiSolidEditLocation, BiMenuAltLeft, BiBookContent } from "react-icons/bi";
import { FaRegCircleUser } from "react-icons/fa6";
import { IoSettingsOutline, IoNotifications, IoTrophySharp } from "react-icons/io5";
import { BsPinMapFill } from "react-icons/bs";
import { SlLogout } from "react-icons/sl";
import DeleteModal from "../ModalContainer/DeleteModal";

export default function ProtectedLayout({ selectedRoute, isSearchBar, children }) {

    const dispatch = useDispatch();
    const [collapsed, setCollapsed] = useState(false)

    const items = [
        {
            icon: <AiOutlineHome />,
            label: "Dashboard",
        },
        {
            icon: <FaRegCircleUser />,
            label: "User Management",
        },
        {
            icon: <IoTrophySharp />,
            label: "Leaderboard",
        },
        {
            icon: <BiSolidEditLocation />,
            label: "Game Management",
        },
        {
            icon: <BiMenuAltLeft />,
            label: "Menu Management",
        },
        {
            icon: <BsPinMapFill />,
            label: "Map Management",
        },
        {
            icon: <IoNotifications />,
            label: "Notifications",
        },
        {
            icon: <BiBookContent />,
            label: "Content Management",
        },
        {
            icon: <IoSettingsOutline />,
            label: "Settings",
        },

        // {
        //     icon: privacy,
        //     label: "Privacy Policy",
        // },


    ]

    function handleLogout() {
        dispatch(updateKey({ isLoggedIn: false }));
        dispatch(profileReset());
        dispatch(searchReset())
        localStorage.removeItem(process.env.REACT_APP_WEB_STORAGE_TOKEN)

    }

    function submit(title, message, cb, rejectCB = () => { }) {
        confirmAlert({
            customUI: ({ onClose }) => {
                return (
                    <DeleteModal
                        text={message}
                        handleClose={onClose}
                        handleDelete={() => {
                            cb();
                            onClose();
                        }}

                    />
                );
            },
            overlayClassName: "confirm-alert-background-change"
        });
    }


    const handleToggle = () => {
        setCollapsed(!collapsed)
    }

    const handleMenuClose = () => {
        setCollapsed(false)
    }
    return (
        <div className={styles.protectedLayout}>
            <div className={collapsed ? styles.background : ''} onClick={handleMenuClose}></div>
            <div
                className={`${styles.menu} ${collapsed ? `${styles.collapsed} visible` : styles.notCollapsed}`}
                id="sider"
            >

                <Link className={`${styles.logo} ${!collapsed ? styles.notCollapsed : ''}`} to={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`}>
                    <img src={'/logo.png'} alt="Logo Image" className={collapsed ? styles.imageCollapsed : styles.imageNotCollapsed} />
                    <span onClick={handleToggle} className={`${styles.drawer} ${collapsed ? styles.collapsed : ''}`}>
                        <GiHamburgerMenu />
                    </span>
                </Link>

                <hr className={styles.divider} />



                <div className={styles.itemContainer}>

                    {items.map(({ icon, label }, index) => {
                        return <Link
                            to={`${process.env.REACT_APP_DOMAIN_DIR}/${label.toLowerCase().replace(/[^A-Z0-9]/ig, "-")}`}
                            className={`${styles.item} ${index == selectedRoute ? styles.active : ""}`}
                            key={index}>
                            {icon}
                            {collapsed ? <p>{label}</p> : ''}
                        </Link>
                    })}

                    <Link className={`${styles.item} ${styles.logout}`} onClick={() => submit("Confirm Logout", "Are you sure you want to logout?", () => handleLogout())} >
                        <SlLogout />
                        {collapsed ? <p>Logout</p> : ''}
                    </Link>

                </div>
            </div>

            <div className={styles.routeContent}>
                <Navbar isSearchBar={false} title={items[selectedRoute].label} handleToggle={handleToggle} />
                <div className={styles.content}>
                    {children}
                </div>
            </div>
        </div >
    )
}