import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { StaticText } from '../Api/StaticText';
import { Toast } from '../hooks/useToast';


export const fetchStaticTextThunk = createAsyncThunk(
    'staticText/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await StaticText.fetch({ api_token });
            return response.data
        } catch (err) {
            return Promise.reject(err);
        }
    }
)

export const updateStaticTextThunk = createAsyncThunk(
    'staticText/update',
    async ({ data }, { getState }) => {
        Toast('Page Updating', 'loading', true);
        try {
            const { api_token } = getState().profile.data;
            const body = getState().staticText.data;
            const payload = {
                terms: body.terms,
                privacy: body.privacy,
                ...data
            }
            const response = await StaticText.update(payload, api_token);
            Toast("Page Updated Successfully", 'success', false);
            return payload
        } catch (err) {
            Toast(err.message, 'error', false);
            return Promise.reject(err);
        }
    }
)

const initialState = {
    status: "pending",
    data: {},
}

export const staticTextSlice = createSlice({
    name: 'staticText',
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        },
        setStatus: (state, actions) => {
            return {
                ...state,
                status: actions.payload
            }
        },
    },
    extraReducers: {
        [fetchStaticTextThunk.fulfilled]: (state, action) => {
            state.status = "success"
            state.data = action.payload
        },
        [fetchStaticTextThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [fetchStaticTextThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updateStaticTextThunk.fulfilled]: (state, action) => {
            state.status = "success"
            state.data = action.payload;
        },
        [updateStaticTextThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateStaticTextThunk.rejected]: (state, action) => {
            state.status = "error"
        },
    }
})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = staticTextSlice.actions

export default staticTextSlice.reducer