import React, { useCallback, useEffect, useState } from "react";

/**Import Styles and Images */
import styles from "./index.module.scss"

import TableLayout from "../../components/TableLayout";
import { useMenuHeader } from "../../schemas/tableHeaderSchema";

import { useSelector, useDispatch } from "react-redux";
import { resetValue } from "../../features/SearchReducer";
import ContentContainer from "../../components/ContentContainer";
import { createMenuThunk, fetchMenuThunk, updateMenuThunk } from "../../features/MenuReducer";
import ModalContainer from "../../components/ModalContainer/index.js";
import { Input, Select, Upload } from "antd";
import { Toast } from "../../hooks/useToast";
import _ from 'lodash';

import { fetchLookupsThunk } from "../../features/LookupReducer.js";
import { useNavigate } from "react-router-dom";

import { ReactComponent as DashboardIcon } from "./images/dashboard.svg";
import { GrNext } from "react-icons/gr"
import { RxCross2 } from "react-icons/rx"
import { AiOutlinePlus } from "react-icons/ai"
import SearchBar from "../../components/CustomizeComponents/SearchBar.js";
import { Excel } from "antd-table-saveas-excel";

export default function FoodMenu() {
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');
    const navigate = useNavigate()
    const MODAL_INITIAL = {
        isModal: false,
        isEdit: false,
        data: {
            item_name: "",
            category_id: undefined,
            price: undefined,
            image: ""
        }
    };
    const [isSubmitted, setIsSubmitted] = useState(false)
    const { data, status } = useSelector(state => state.menu);
    const { data: lookupsData, status: lookupsStatus } = useSelector(state => state.lookups);
    const [list, setList] = useState(data);
    const [addOrEditMenu, setAddOrEditMenu] = useState(MODAL_INITIAL);
    const dispatch = useDispatch();
    const header = useMenuHeader(handleUpdateMenu)

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchMenuThunk());
        }

        if (lookupsStatus !== 'success') {
            dispatch(fetchLookupsThunk());
        }

        return () => dispatch(resetValue())
    }, [])

    useEffect(() => {
        if (!search) return setList(data);


        const array = [
            ...data.map(item => {
                if (item.item_name.toLowerCase().includes(search.toLowerCase())) {
                    return item
                }
            })
        ].filter(Boolean)
        setList(array);

    }, [data, search])


    const handleInputChange = useCallback((e) => {
        const { name, value } = e.target;
        setAddOrEditMenu(prev => {
            return {
                ...prev,
                data: {
                    ...prev.data,
                    [name]: value
                }
            }
        })
    }, [])

    const handleSelectChange = (name, value) => {
        setAddOrEditMenu(prev => {
            return {
                ...prev,
                data: {
                    ...prev.data,
                    [name]: value
                }
            }
        })
    }

    const beforeUpload = (file) => {
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG file!', 'error', false);
        }
        else if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        return isJpgOrPng && isLt2M;
    };

    const handleFileChange = (info) => {
        setAddOrEditMenu(prev => {
            return {
                ...prev,
                data: {
                    ...prev.data,
                    item_image: info.file.originFileObj
                }
            }
        });
    }


    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };



    const handleAddOrEditButtonClick = () => {
        setAddOrEditMenu(prev => {
            return {
                ...prev,
                isModal: true,
                isEdit: false,
            }
        })
    }

    function handleUpdateMenu(record) {
        setAddOrEditMenu(prev => {
            return {
                ...prev,
                isModal: true,
                isEdit: true,
                data: record
            }
        })

    }

    const handleModalClose = useCallback(() => {
        setAddOrEditMenu(MODAL_INITIAL)
    }, [])

    const handleSubmitForm = () => {
        if (isSubmitted) return;

        const form = addOrEditMenu.data
        // if (!form.item_image) return Toast('Please upload item image', 'error', false)
        if (!form.category_id) return Toast('Plase select item category', 'error', false)
        if (!form.item_name) return Toast('Item name should not be empty', 'error', false)
        if (!form.price) return Toast('Item price should not be empty', 'error', false)
        if (parseFloat(form.price) < 0) return Toast('Item price should be greater than 0', 'error', false)

        setIsSubmitted(true);
        if (addOrEditMenu.isEdit) {
            dispatch(updateMenuThunk({ data: form, handleModalClose, setIsSubmitted }))
        }
        else {
            dispatch(createMenuThunk({ data: form, handleModalClose, setIsSubmitted }))
        }
    }

    const handleSaveExcel = () => {
        const excel = new Excel();
        excel
            .addSheet("Food Menu")
            .addColumns([
                {
                    title: "Item Name",
                    dataIndex: "item_name",
                    key: "item_name"
                },
                {
                    title: "Item Price",
                    dataIndex: "price",
                    key: "price"
                },
                {
                    title: "Item Category",
                    dataIndex: "category_name",
                    key: "category_name"
                },
            ])
            .addDataSource(list.map(item => {
                return {
                    item_name: item.item_name,
                    price: `$${item.price}`,
                    category_name: item.category_name,
                }
            }), {
                str2Percent: true
            })
            .saveAs("Food Menu.xlsx");
    }



    return (
        <div className={styles.contentLayout}>
            <div className={styles.titleContainer}>
                <div className={styles.headerContainer}>
                    <h1 className={styles.title}>Menu Management</h1>
                    {/* <div className={styles.verticalDivider}></div> */}
                    <div className={styles.endpointContainer}>
                        <div
                            className={styles.iconContainer}
                            onClick={() => navigate('/dashboard')}
                        >
                            <DashboardIcon />
                        </div>
                        <span className={styles.next}><GrNext /></span>
                        <h2 className={styles.endpoint}>Menu Management</h2>
                    </div>
                    <div className={styles.addButtonContainer}>
                        <button className={styles.button} onClick={handleAddOrEditButtonClick}>
                            Create<span>< AiOutlinePlus /></span>
                        </button>
                    </div>
                </div>
                <hr className={styles.divider} />

            </div>
            <ContentContainer hasOptions={false} >
                {addOrEditMenu.isModal ? <ModalContainer handleClose={handleModalClose}>
                    <div className={styles.modal}>
                        <div className={styles.headerContainer}>
                            <h1 className={styles.title}>{!addOrEditMenu.isEdit ? 'Add Menu' : 'Edit Menu'}</h1>
                            <span onClick={handleModalClose}><RxCross2 /></span>
                        </div>
                        <hr />
                        <div className={styles.fieldsContainer}>
                            {/* <Upload
                                name="image"
                                listType="picture-card"
                                className={`avatar-uploader ${styles.container}`}
                                showUploadList={false}
                                beforeUpload={beforeUpload}
                                accept="image/jpeg,image/png,image/png"
                                onChange={handleFileChange}
                                customRequest={dummyRequest}
                            >
                                {!_.isEmpty(addOrEditMenu.data.item_image) ?
                                    <img
                                        src={!_.isObject(addOrEditMenu.data.item_image) ? addOrEditMenu.data.item_image : URL.createObjectURL(addOrEditMenu.data.item_image)}
                                        alt="avatar"
                                        style={{ width: '100%', height: '100%' }} /> :
                                    <>
                                        <span className={styles.uploadIcon}><AiOutlinePlus /></span>
                                        <h3>Add Image</h3>
                                    </>
                                }
                            </Upload> */}
                            <p className={styles.label}>Item Category</p>
                            <Select
                                size="large"
                                name="category_id"
                                placeholder="Select Category"
                                onChange={(value) => handleSelectChange('category_id', value)}
                                value={addOrEditMenu.data.category_id}
                                className={styles.field}
                                loading={(lookupsStatus == 'pending')}
                                options={(lookupsData?.category ?? []).map(item => {
                                    return {
                                        value: item.id,
                                        label: item.title
                                    }
                                }
                                )}
                            />
                            <p className={styles.label}>Item Name</p>
                            <Input
                                size="large"
                                placeholder="Enter Item Name"
                                className={styles.field}
                                name='item_name'
                                value={addOrEditMenu.data.item_name}
                                onChange={handleInputChange}
                            />
                            <p className={styles.label}>Item Price</p>
                            <Input
                                size="large"
                                type="number"
                                placeholder="Enter Item Price"
                                className={styles.field}
                                name='price'
                                value={addOrEditMenu.data.price}
                                onChange={handleInputChange}
                            />
                            <button className={styles.submit} onClick={handleSubmitForm}>
                                Save
                            </button>
                        </div>
                    </div>
                </ModalContainer> : ""
                }
                <div className={styles.menu}>
                    <SearchBar value={search} onChange={(value) => setSearch(value)} />
                    <TableLayout
                        isLoading={(status == 'pending')}
                        data={list}
                        headers={header}
                        pageNo={currentPage}
                        handlePageChange={(pageNo) => setCurrentPage(pageNo)}
                    />
                    {(status === 'success') ? <div className={styles.toolContainer}>
                        <button onClick={handleSaveExcel} className={styles.excel}>Excel</button>

                        <p className={styles.recordCount}>
                            {`Showing ${(currentPage - 1) * 8 + 1} to ${(currentPage - 1) * 8 + 8} of ${list.length} entries`}
                        </p>
                    </div> : ""}
                </div>
            </ContentContainer >
        </ div >
    )
}