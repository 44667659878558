import React, { useEffect, useMemo, useState } from "react";

import styles from "./index.module.scss"
import DashBoardUser from "./images/total-mama.png"
import LeaderboardImage from "./images/leaderboard.png"

import { Dashboard } from "../../Api/Dashboard";
import { Toast } from "../../hooks/useToast";

import { Col, Row } from "antd";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import Chart from 'chart.js/auto';

const antIcon = <LoadingOutlined style={{ fontSize: 35, color: "white" }} spin />;
const antChartIcon = <LoadingOutlined style={{ fontSize: 35 }} spin />;

export default function DashBoard() {

    const { api_token } = useSelector(state => state.profile.data)
    const [chartType, setChartType] = useState('users')
    const { data, isLoading } = useQuery({
        queryKey: ['dashboard'],
        queryFn: () => Dashboard.get(api_token),
        refetchOnMount: true,
        refetchOnReconnect: true,
        refetchOnWindowFocus: false,
        onError: (err) => Toast(err.message, 'error', false)
    })
    console.log(data)
    const item = useMemo(() => [
        {
            type: "users",
            image: DashBoardUser,
            number: data?.data.user_count || 0,
            title: "Total Users",
            info: "APP Sign Ups",
            style: { background: `#7a9215` },
        },
        {
            type: "leaderboard",
            image: LeaderboardImage,
            number: data?.data.leaderboard_count || 0,
            title: "Leaderboard",
            info: "Last Winners",
            style: { background: `#7a9215` },
        },
    ], [isLoading])

    useEffect(() => {

        if (isLoading) return;
        var userctx = document.getElementById("user_convo")?.getContext('2d');
        var ctx = document.getElementById("chart1_convo")?.getContext('2d');

        var gradientStroke1_event = ctx.createLinearGradient(0, 0, 0, 300);
        gradientStroke1_event.addColorStop(0, '#7a9215');
        gradientStroke1_event.addColorStop(1, '#7a9215');

        var gradientStroke2_event = ctx.createLinearGradient(0, 0, 0, 300);
        gradientStroke2_event.addColorStop(0, '#ff8008');
        gradientStroke2_event.addColorStop(1, '#ff8008');

        var gradientStroke3_event = ctx.createLinearGradient(0, 0, 0, 300);
        gradientStroke3_event.addColorStop(0, '#ddae04');
        gradientStroke3_event.addColorStop(1, '#ddae04');

        var myChart = new Chart(ctx, {
            type: 'bar',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    label: 'Daily',
                    data: data?.data.chart.daily || Array(12).fill(0),
                    borderColor: gradientStroke1_event,
                    backgroundColor: gradientStroke1_event,
                    hoverBackgroundColor: gradientStroke1_event,
                    pointRadius: 0,
                    fill: false,
                    borderRadius: 20,
                    borderWidth: 0
                }, {
                    label: 'Weekly',
                    data: data?.data.chart.weekly || Array(12).fill(0),
                    borderColor: gradientStroke2_event,
                    backgroundColor: gradientStroke2_event,
                    hoverBackgroundColor: gradientStroke2_event,
                    pointRadius: 0,
                    fill: false,
                    borderRadius: 20,
                    borderWidth: 0
                }, {
                    label: 'Monthly',
                    data: data?.data.chart.monthly || Array(12).fill(0),
                    borderColor: gradientStroke3_event,
                    backgroundColor: gradientStroke3_event,
                    hoverBackgroundColor: gradientStroke3_event,
                    pointRadius: 0,
                    fill: false,
                    borderRadius: 20,
                    borderWidth: 0
                }

                ]
            },

            options: {
                maintainAspectRatio: false,
                barPercentage: 0.5,
                categoryPercentage: 0.8,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });



        var gradientStroke1_event = userctx.createLinearGradient(0, 0, 0, 300);
        gradientStroke1_event.addColorStop(0, '#7a9215');
        gradientStroke1_event.addColorStop(1, '#7a9215');

        var gradientStroke2_event = userctx.createLinearGradient(0, 0, 0, 300);
        gradientStroke2_event.addColorStop(0, '#ff8008');
        gradientStroke2_event.addColorStop(1, '#ff8008');

        var gradientStroke3_event = userctx.createLinearGradient(0, 0, 0, 300);
        gradientStroke3_event.addColorStop(0, '#ddae04');
        gradientStroke3_event.addColorStop(1, '#ddae04');

        var userChart = new Chart(userctx, {
            type: 'bar',
            data: {
                labels: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                datasets: [{
                    label: 'Monthly',
                    data: data?.data.user_monthly || Array(12).fill(0),
                    borderColor: gradientStroke1_event,
                    backgroundColor: gradientStroke1_event,
                    hoverBackgroundColor: gradientStroke1_event,
                    pointRadius: 0,
                    fill: false,
                    borderRadius: 20,
                    borderWidth: 0
                }]
            },

            options: {
                maintainAspectRatio: false,
                barPercentage: 0.5,
                categoryPercentage: 0.8,
                plugins: {
                    legend: {
                        display: false,
                    }
                },
                scales: {
                    y: {
                        beginAtZero: true
                    }
                }
            }
        });


        return () => {
            myChart?.destroy()
            userChart?.destroy()
        }

    }, [isLoading, chartType])

    return (

        <div className={styles.cardContainer}>
            <Row gutter={[16, 16]} className={styles.row}>
                {item.map((i, index) => {
                    return <Col xxl={6} xl={8} lg={8} sm={12} index={index} key={index} onClick={()=>setChartType(i.type)}>
                        <div className={styles.card} style={i.style}>
                            <div className={styles.infoContainer}>
                                <p className={styles.title}>{i.title}</p>
                                <h4 className={styles.count}>{isLoading ? <Spin indicator={antIcon} size="large" /> : i.number}</h4>
                                <h4 className={styles.info}>{i.info}</h4>
                            </div>
                            <div className={styles.imageBack} >
                                <img src={i.image} alt="Icon" />
                            </div>
                        </div>
                    </Col>
                })}
            </Row>
            <Row gutter={[16, 16]} className={styles.row}>
                <div className={styles.chartContainer}>

                    <h6 className={styles.title}>Progress Overview</h6>
                    <div className={styles.chart}>
                        {isLoading ? <Spin indicator={antChartIcon} size="large" /> :
                            <>
                                <canvas id="user_convo" className={(chartType === 'leaderboard') ? styles.hidden : ""}></canvas>
                                <canvas id="chart1_convo" className={(chartType === 'users') ? styles.hidden : ""}></canvas>
                            </>}
                    </div>
                </div >

            </Row >
        </div >
    )
}