import React, { useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from 'react-router-dom';

import 'antd/dist/antd.min.css';

import { useLocalStorage } from "./hooks/useLocalStorage";

import ProtectedLayout from "./components/ProtectedLayout";
import ContentLayout from "./components/ContentLayout";

import PublicRoute from "./components/Routes/PublicRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";

import Login from "./pages/Login";
import ChangePassword from "./pages/ChangePassword";
import Dashboard from "./pages/Dashboard";
import UserManagement from "./pages/UserManagement";
import FoodMenu from "./pages/Menu";
import Notification from "./pages/Notification.js";
import GameManagement from "./pages/GameMangement/index.js";
import LeaderBoard from "./pages/LeaderBoard/index.js";
import ContentManagement from "./pages/ContentManagement/index.js";
import MapManagement from "./pages/MapManagement/index.js";




function App() {
  const user = useLocalStorage();

  return (
    <Router>
      <Routes>
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/login`} element={
          <PublicRoute>
            <Login />
          </PublicRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/dashboard`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='0' isSearchBar={true}>
              <ContentLayout>
                <Dashboard />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/user-management`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='1' isSearchBar={true}>
              <ContentLayout title="Users Management">
                <UserManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/leaderboard`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='2' isSearchBar={true}>
              <ContentLayout title="Leaderboard">
                <LeaderBoard />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/game-management`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='3' isSearchBar={true}>
              <ContentLayout title="Game Management">
                <GameManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/menu-management`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='4' isSearchBar={true}>
              <FoodMenu />
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/map-management`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='5' isSearchBar={true}>
              <ContentLayout title="Map Management">
                <MapManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/notifications`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='6' isSearchBar={true}>
              <Notification />
            </ProtectedLayout>
          </ProtectedRoute>
        } />

        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/content-management`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='7' isSearchBar={true}>
              <ContentLayout title="Content Management">
                <ContentManagement />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />

        <Route exact path={`${process.env.REACT_APP_DOMAIN_DIR}/settings`} element={
          <ProtectedRoute >
            <ProtectedLayout selectedRoute='8' isSearchBar={true}>
              <ContentLayout title="Settings">
                <ChangePassword />
              </ContentLayout>
            </ProtectedLayout>
          </ProtectedRoute>
        } />
        <Route path={`${process.env.REACT_APP_DOMAIN_DIR}/*`} element={<Navigate to={`${process.env.REACT_APP_DOMAIN_DIR}/login`} />} />
      </Routes >


    </Router >
  );
}

export default App;
