import React, { useEffect, useState } from 'react';

import styles from "./index.module.scss";
import { ReactComponent as Daily } from './images/daily.svg';
import { ReactComponent as ActiveDaily } from './images/active_daily.svg';
import { ReactComponent as Weekly } from './images/weekly.svg';
import { ReactComponent as ActiveWeekly } from './images/active.svg';

import ContentContainer from '../../components/ContentContainer';
import { useLeaderboardHeader } from '../../schemas/tableHeaderSchema';
import TableLayout from '../../components/TableLayout';
import { fetchLeaderBoardThunk } from '../../features/LeaderboardReducer';
import Tabs from '../../components/CustomizeComponents/Tabs';

import { useDispatch, useSelector } from 'react-redux';
import _ from "lodash"
import SearchBar from '../../components/CustomizeComponents/SearchBar';
import { Excel } from "antd-table-saveas-excel";

export default function LeaderBoard() {
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');

    const SORT_OPTIONS = [
        { key: 'daily', label: 'Daily', icon: <Weekly />, active_icon: <ActiveWeekly /> },
        { key: 'weekly', label: 'Weekly', icon: <Daily />, active_icon: <ActiveDaily /> },
        { key: 'monthly', label: 'Monthly', icon: <Daily />, active_icon: <ActiveDaily /> },
    ]

    const GAME_OPTIONS = [
        { key: '10', label: 'Adventure' },
        { key: '20', label: 'Wild Hunt' },
    ]
    const [sortType, setSortType] = useState('daily');
    const [gameType, setGameType] = useState('10');

    const dispatch = useDispatch();
    const { data, status } = useSelector(state => state.leaderboard)
    const [list, setList] = useState([]);
    const header = useLeaderboardHeader();

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchLeaderBoardThunk());
        }
        // return () => dispatch(resetValue())
    }, [])

    useEffect(() => {
        if (_.isEmpty(data)) return setList([])
        if (!search) return setList((data[sortType][gameType] ?? []));

        const array = [
            ...data[sortType][gameType]?.map(item => {
                if (item?.firstname.toLowerCase().includes(search.toLowerCase()) || item?.lastname.toLowerCase().includes(search.toLowerCase())) {
                    return item
                }
            })
        ].filter(Boolean)
        setList(array);

    }, [data, sortType, gameType, search])



    const handleSelectChange = (value) => {
        setGameType(value);
    }

    const handleTabChange = (value) => {
        setSortType(value);
    }

    const handleSaveExcel = () => {
        const excel = new Excel();
        excel
            .addSheet(`${sortType}-${(gameType == 10) ? "adventure" : "wild_hunt"}`)
            .addColumns([
                {
                    title: "First Name",
                    dataIndex: "firstname",
                    key: "firstname"
                },
                {
                    title: "Last Name",
                    dataIndex: "lastname",
                    key: "lastname"
                },
                {
                    title: "Game Category",
                    dataIndex: "game",
                    key: "game"
                },
                {
                    title: "Date",
                    dataIndex: "date",
                    key: "date"
                },
                {
                    title: "Time Interval",
                    dataIndex: "duration",
                    key: "duration"
                },
                {
                    title: "Position",
                    dataIndex: "position",
                    key: "position"
                }
            ])
            .addDataSource(list.map(item => {
                return {
                    firstname: item.firstname,
                    lastname: item.lastname,
                    game: item.game,
                    date: item.date,
                    duration: item.duration,
                    position: item.position,
                }
            }), {
                str2Percent: true
            })
            .saveAs("Leaderboard.xlsx");
    }

    console.log(data)

    return (
        <ContentContainer>
            <div className={styles.leaderboard}>
                <div className={styles.selectContainer}>
                    <Tabs
                        current={sortType}
                        onChange={handleTabChange}
                        tabs={SORT_OPTIONS}
                    />
                    <Tabs
                        current={gameType}
                        onChange={handleSelectChange}
                        tabs={GAME_OPTIONS}
                        align='right'
                    />
                </div>
                <SearchBar hasDivider={false} value={search} onChange={(value) => setSearch(value)} />
                <TableLayout
                    headers={header}
                    data={(_.isEmpty(data) ? [] : list)}
                    status={'success'}
                    isLoading={(status == 'pending')}
                    pageNo={currentPage}
                    handlePageChange={(page) => setCurrentPage(page)}
                />
                {status === 'success' ? <div className={styles.toolContainer}>
                    <button onClick={handleSaveExcel} className={styles.excel}>Excel</button>

                    <p className={styles.recordCount}>
                        {`Showing ${(currentPage - 1) * 8 + 1} to ${(currentPage - 1) * 8 + 8} of ${list.length} entries`}
                    </p>
                </div> : ""}
            </div>
        </ContentContainer>
    )
}