import React, { useEffect, useState } from "react";

import styles from "./index.module.scss"
import ContentContainer from "../../components/ContentContainer";
import Tabs from "../../components/CustomizeComponents/Tabs";
import Editor from "./Editor";
import { fetchStaticTextThunk, updateStaticTextThunk } from "../../features/StaticTextReducer";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;


export default function ContentManagement() {
    const dispatch = useDispatch();
    const TABS_OPTIONS = [
        { label: 'Terms And Condition', key: "terms" },
        { label: 'Privacy Policy', key: "privacy" }
    ]

    const { data, status } = useSelector(state => state.staticText);
    const [currentTab, setCurrentTab] = useState('terms');
    const [value, setValue] = useState(data[currentTab] ?? '');

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchStaticTextThunk());
        }
    }, [])

    const handleTabChange = (value) => {
        setCurrentTab(value)
        setValue(data[value])
    }

    const handleSave = () => {
        const body = {
            [currentTab]: value
        }
        dispatch(updateStaticTextThunk({ data: body }))
    }

    return (
        <ContentContainer>
            <div className={styles.contentManagement}>
                <div className={styles.tabComponentContainer}>
                    <Tabs
                        current={currentTab}
                        tabs={TABS_OPTIONS}
                        onChange={handleTabChange}

                    />
                    <button
                        className={styles.button}
                        onClick={handleSave}
                    >
                        Save
                    </button>
                </div>
                <div className={styles.editorContainer}>
                    {(status === 'pending') ? < Spin indicator={antIcon} style={{ fontSize: "24px" }} /> :
                        <ReactQuill theme="snow" value={value} onChange={setValue} className={styles.editor} />
                        // <Editor data={data[currentTab] ?? ''} key={currentTab} keyName={currentTab} />
                    }
                </div>

            </div>
        </ContentContainer>
    )
}