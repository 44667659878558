import { Axios } from "../hooks/useAxiosInstance";

export const Leaderboard = (function () {


    const apis = () => ({
        get: async (api_token) => await getRecord(api_token),
        delete: async (id, api_token) => await deleteRecord(id, api_token),
        update: async (id, api_token) => await updateRecord(id, api_token),

    })


    async function getRecord(api_token) {
        const url = `admin/games/leaderboard`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }

    async function deleteRecord(id, api_token) {
        const url = `admin/games/leaderboard/user/${id}`;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.delete(url, option);
    }

    async function updateRecord(id, api_token) {
        const url = `admin/games/leaderboard/user/${id}`;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.patch(url, option);
    }

    return apis();

})()
