import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Map } from '../Api/Map';
import { Toast } from '../hooks/useToast';


export const fetchMapThunk = createAsyncThunk(
    'map/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Map.get(api_token);
            return response.data
        } catch (err) {
            return Promise.reject(err);
        }
    }
)


export const updateMapThunk = createAsyncThunk(
    'map/update',
    async ({ data, setIsSubmitted }, { getState }) => {
        Toast('Map data Updating', 'loading', true);
        try {
            const { api_token } = getState().profile.data;
            const response = await Map.update(data, api_token);
            Toast("Map Updated successfully", 'success', false);
            return response.data
        } catch (err) {
            Toast(err.message, 'error', false);
            return Promise.reject(err);
        }
        finally {
            setIsSubmitted(false)
        }
    }
)

const initialState = {
    status: "pending",
    data: {},
}

export const mapSlice = createSlice({
    name: 'map',
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        },
    },
    extraReducers: {
        [fetchMapThunk.fulfilled]: (state, action) => {
            state.status = "success"
            state.data = action.payload
        },
        [fetchMapThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [fetchMapThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [updateMapThunk.fulfilled]: (state, action) => {
            state.status = "success"
            state.data = action.payload
        },
        [updateMapThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateMapThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }
})


// Action creators are generated for each case reducer function
export const { resetState } = mapSlice.actions

export default mapSlice.reducer