import { Axios } from "../hooks/useAxiosInstance";

export const Profile = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        post: async (data) => await postRecord(data),
        update: async (data, slug, api_token) => await updateProfile(data, slug, api_token),
        upload: async (data, slug, api_token) => await uploadImage(data, slug, api_token),
        postForgetPassword: async (data) => await postForget(data),
        updatePassword: async (id, data, api_token) => await updatePassword(id, data, api_token)
    })

    async function postRecord(data) {
        const url = "admin/login";
        const option = {
            headers: {
                'token': '59200748-1234-2233-355-8c1281f7fcd2',
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updateProfile(data, slug, api_token) {
        const url = "admin/" + slug;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.patch(url, data, option);
    }
    async function uploadImage(data, slug, api_token) {
        const url = "admin/upload/" + slug;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.patch(url, data, option);
    }

    async function postForget(data) {
        const url = "admin/forgot-password";
        const option = {
            headers: {
                'token': '59200748-1234-2233-355-8c1281f7fcd2',
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function updatePassword(id, data, api_token) {
        const url = "admin/change-password/" + id;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'multipart/form-data'
            }
        }

        return await Axios.patch(url, data, option);
    }

    return apis();

})()
