import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Game } from '../Api/Game';
import { Toast } from '../hooks/useToast';

export const fetchGamesThunk = createAsyncThunk(
    'games/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data.user;
            const response = await Game.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const updateStartOrEndPointThunk = createAsyncThunk(
    'game/update/start_end',
    async ({ data, gameDifficulty, setIsSubmitted }, { getState }) => {
        Toast("Updating points", "loading", true);
        try {
            const { api_token } = getState().profile.data.user;
            const response = await Game.update(gameDifficulty, data, api_token);
            Toast("Points updated successfully", "success", false);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)

export const addOrUpdatePointThunk = createAsyncThunk(
    'game/update',
    async ({ data, gameType, gameDifficulty = '', setIsSubmitted, handleModalClose = () => { } }, { getState }) => {
        Toast("Updating points", "loading", true);
        try {
            const { api_token } = getState().profile.data.user;
            let endpoint = (gameType == 'game2') ? `${gameType}/${gameDifficulty}` : gameType
            const response = await Game.addOrUpdate(data, endpoint, api_token);
            Toast("Points added successfully", "success", false);
            handleModalClose()
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)
export const deletePointThunk = createAsyncThunk(
    'game/delete',
    async ({ point_name = '', gameType = '', gameDifficulty = '' }, { getState }) => {
        Toast("Deleting point", "loading", true);
        try {
            const { api_token } = getState().profile.data.user;
            const response = await Game.delete(api_token, point_name, gameType, gameDifficulty);
            Toast("Points deleted successfully", "success", false);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)




const initialState = {
    status: "pending",
    data: {},
}

export const gamesSlice = createSlice({
    name: 'games',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                status: actions.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [fetchGamesThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchGamesThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [fetchGamesThunk.rejected]: (state, action) => {
            state.status = "rejected"
        },
        [updateStartOrEndPointThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [updateStartOrEndPointThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateStartOrEndPointThunk.rejected]: (state, action) => {
            state.status = "success"
        },
        [addOrUpdatePointThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [addOrUpdatePointThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [addOrUpdatePointThunk.rejected]: (state, action) => {
            state.status = "success"
        },
        [deletePointThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [deletePointThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deletePointThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }

})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = gamesSlice.actions
export default gamesSlice.reducer