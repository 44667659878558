import React, { useEffect, useMemo, useState } from "react";

import styles from "./index.module.scss";

import { Toast } from "../../hooks/useToast";

import { Col, Input, Row, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineEyeInvisible } from "react-icons/ai";
import { MdOutlineVisibility } from "react-icons/md"
import { Upload } from 'antd';
import { fetchMapThunk, updateMapThunk } from "../../features/MapReducer";
import { LoadingOutlined } from '@ant-design/icons';
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export default function MapManagement() {
    const dispatch = useDispatch()
    const [isUploading, setIsUploading] = useState(false);
    const { data: mapData, status } = useSelector(state => state.map);
    const [data, setData] = useState({
        latitude: null,
        longitude: null,
    });
    const [isSubmitted, setIsSubmitted] = useState(false);
    const isDataLoading = useMemo(() => (status === 'pending'), [status])


    useEffect(() => {
        if (status === "success") {
            setData(prev => {
                return {
                    ...prev,
                    latitudeBL: mapData.latitudeBL,
                    longitudeBL: mapData.longitudeBL,
                    latitudeTR: mapData.latitudeTR,
                    longitudeTR: mapData.longitudeTR,
                }
            })
        }
        else {
            dispatch(fetchMapThunk());
        }
    }, [status])

    function handleChange(e) {
        const { name, value } = e.target;
        setData(prev => {
            return {
                ...prev,
                [name]: value
            }
        })
    }

    async function handleSubmit() {
        if (isSubmitted) return;

        if (!data.latitudeBL) return Toast("Bottom Left Latitude Should Not Be Empty", 'error', false);
        if (!data.longitudeBL) return Toast("Bottom Left Longitude Should Not Be Empty", 'error', false);
        if (!data.latitudeTR) return Toast("Top Right Latitude Should Not Be Empty", 'error', false);
        if (!data.longitudeTR) return Toast("Top Right Longitude Should Not Be Empty", 'error', false);

        setIsSubmitted(true);
        dispatch(updateMapThunk({
            data,
            setIsSubmitted
        }))
    }

    const beforeUpload = (file) => {
        if (isUploading) return false;
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isJpgOrPng) {
            Toast('You can only upload JPG/PNG file!', 'error', false);
        }
        else if (!isLt2M) {
            Toast('Image must smaller than 2MB!', 'error', false);
        }
        if (!isJpgOrPng || !isLt2M) return false;


        setIsUploading(true)
        const data = new FormData()
        data.append('image', file);
        dispatch(updateMapThunk({ data, setIsSubmitted: setIsUploading }))



    };

    const dummyRequest = ({ file, onSuccess }) => {
        setTimeout(() => {
            onSuccess("ok");
        }, 0);
    };

    return (
        <div className={styles.MapManagement}>
            <div className={styles.imageContainer}>
                {isDataLoading ? <Spin indicator={antIcon} style={{ fontSize: "24px" }} /> :
                    <>
                        <img src={mapData?.image ?? 'map-placeholder.jpeg'} alt="User Image" className={styles.userImage} />
                        <Upload
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            accept="image/jpeg,image/png,image/png"
                            customRequest={dummyRequest}
                        >
                            <button className={styles.upload}>Upload Photo</button>
                        </Upload>
                    </>
                }
            </div>
            <div className={styles.passwordContainer}>
                <Row className={styles.row}>
                    <Col xs={24}>
                        <h3 className={styles.title}>Bottom Left Coordinates</h3>
                    </Col>
                    <Col lg={8} xs={24}>
                        <p className={styles.title}>Enter Latitude</p>
                    </Col>
                    <Col lg={16} xs={24}>
                        {isDataLoading ? <Spin indicator={antIcon} style={{ fontSize: "24px" }} /> :
                            <Input
                                type="number"
                                size="large"
                                autoComplete="off"
                                name="latitudeBL"
                                value={data.latitudeBL}
                                onChange={handleChange}
                                iconRender={(visible) => (visible ? <MdOutlineVisibility /> : <AiOutlineEyeInvisible />)}
                            />
                        }
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col lg={8} xs={24}>
                        <p className={styles.title}>Enter Longitude</p>
                    </Col>
                    <Col lg={16} xs={24}>
                        {isDataLoading ? <Spin indicator={antIcon} style={{ fontSize: "24px" }} /> :
                            <Input
                                type="number"
                                size="large"
                                autoComplete="off"
                                name="longitudeBL"
                                value={data.longitudeBL}
                                onChange={handleChange}
                            />
                        }
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col xs={24}>
                        <h3 className={`${styles.title} ${styles.top}`}>Top Right Coordinates</h3>
                    </Col>
                    <Col lg={8} xs={24}>
                        <p className={styles.title}>Enter Latitude</p>
                    </Col>
                    <Col lg={16} xs={24}>
                        {isDataLoading ? <Spin indicator={antIcon} style={{ fontSize: "24px" }} /> :
                            <Input
                                type="number"
                                size="large"
                                autoComplete="off"
                                name="latitudeTR"
                                value={data.latitudeTR}
                                onChange={handleChange}
                                iconRender={(visible) => (visible ? <MdOutlineVisibility /> : <AiOutlineEyeInvisible />)}
                            />
                        }
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col lg={8} xs={24}>
                        <p className={styles.title}>Enter Longitude</p>
                    </Col>
                    <Col lg={16} xs={24}>
                        {isDataLoading ? <Spin indicator={antIcon} style={{ fontSize: "24px" }} /> :
                            <Input
                                type="number"
                                size="large"
                                autoComplete="off"
                                name="longitudeTR"
                                value={data.longitudeTR}
                                onChange={handleChange}
                            />
                        }
                    </Col>
                </Row>
                <Row className={styles.row}>
                    <Col lg={8} xs={0}>
                    </Col>
                    <Col lg={16} xs={24}>
                        <button className={styles.submit} onClick={handleSubmit}>
                            Save Changes
                        </button>
                    </Col>
                </Row>
            </div>

        </div >
    )
}