import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Leaderboard } from '../Api/Leaderboard';
import { Toast } from '../hooks/useToast';
import { setStatus as setUserManagementStatus } from "./UserManagementReducer";
export const fetchLeaderBoardThunk = createAsyncThunk(
    'leaderboard/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Leaderboard.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)

export const updateLeaderboardUserThunk = createAsyncThunk(
    'leaderboard/update',
    async (id, { getState }) => {
        try {
            Toast("Updating user status", 'loading', true);
            const { api_token } = getState().profile.data;
            const response = await Leaderboard.update(id, api_token);
            Toast("User status updated successfully!.", 'success', false);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)



export const deleteLeaderboardUserThunk = createAsyncThunk(
    'leaderboard/delete',
    async (id, { getState, dispatch }) => {
        try {
            Toast("Deleting user", 'loading', true);
            const { api_token } = getState().profile.data;
            const response = await Leaderboard.delete(id, api_token);
            Toast("User deleted successfully!.", 'success', false);
            dispatch(setUserManagementStatus({ status: "pending" }))
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)



const initialState = {
    status: "pending",
    data: {},
}

export const LeaderboardSlice = createSlice({
    name: 'leaderboard',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                status: actions.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [fetchLeaderBoardThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchLeaderBoardThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [fetchLeaderBoardThunk.rejected]: (state, action) => {
            state.status = "rejected"
        },
        [updateLeaderboardUserThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [updateLeaderboardUserThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateLeaderboardUserThunk.rejected]: (state, action) => {
            state.status = "success"
        },
        [deleteLeaderboardUserThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [deleteLeaderboardUserThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deleteLeaderboardUserThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }

})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = LeaderboardSlice.actions
export default LeaderboardSlice.reducer