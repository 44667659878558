import { Axios } from "../hooks/useAxiosInstance";

export const Map = (function () {


    const apis = () => ({
        get: async (api_token) => await getRecord(api_token),
        update: async (data, api_token) => await updateRecord(data, api_token),
    })

    async function getRecord(data) {
        const url = "admin/map";
        const option = {
            headers: {
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }


    async function updateRecord(data, api_token) {
        const url = "admin/map";
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'multipart/form-data'
            }
        }
        return await Axios.patch(url, data, option);
    }


    return apis();

})()
