import { Axios } from "../hooks/useAxiosInstance";

export const Game = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        get: async (api_token) => await getRecord(api_token),
        update: async (difficulty, data, api_token) => await updateRecord(difficulty, data, api_token),
        addOrUpdate: async (data, endPoint, api_token) => await addOrUpdateRecord(data, endPoint, api_token),
        delete: async (api_token, point_name, game_type, game_difficulty = '') => await deleteRecord(api_token, point_name, game_type, game_difficulty),
    })

    async function getRecord(api_token) {
        const url = `admin/games`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.get(url, option);
    }
    async function updateRecord(difficulty, data, api_token) {
        const url = `admin/games/game2/${difficulty}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.patch(url, data, option);
    }
    async function addOrUpdateRecord(data, endPoint, api_token) {
        const url = `admin/games/${endPoint}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.patch(url, data, option);
    }
    async function deleteRecord(api_token, point_name, game_type, game_difficulty) {
        const url = `admin/games/point?point_name=${point_name}&game_type=${game_type}&game_difficulty=${game_difficulty}`;
        const option = {
            headers: {
                'Authorization': api_token,
            }
        }

        return await Axios.delete(url, option);
    }

    return apis();

})()
