import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Lookups } from '../Api/Lookup';
import { Toast } from '../hooks/useToast';

export const fetchLookupsThunk = createAsyncThunk(
    'lookups/get',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Lookups.get(api_token);
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)



const initialState = {
    status: "pending",
    data: {},
}

export const LookupSlice = createSlice({
    name: 'lookups',
    initialState,
    reducers: {
        setStatus: (state, actions) => {
            return {
                ...state,
                status: actions.payload
            }
        },
        resetState: () => {
            return initialState
        }
    },
    extraReducers: {
        [fetchLookupsThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchLookupsThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [fetchLookupsThunk.rejected]: (state, action) => {
            state.status = "rejected"
        },
    }

})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = LookupSlice.actions
export default LookupSlice.reducer