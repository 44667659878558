import React, { useEffect, useState } from "react";

/**Import Styles and Images */
import styles from "./UserManagement.module.scss"

import TableLayout from "../../components/TableLayout";
import { useUserManagementHeader } from "../../schemas/tableHeaderSchema";

import { useSelector, useDispatch } from "react-redux";
import { resetValue } from "../../features/SearchReducer";
import ContentContainer from "../../components/ContentContainer";
import { fetchUserManagementThunk } from "../../features/UserManagementReducer";
import SearchBar from "../../components/CustomizeComponents/SearchBar";
import { Excel } from "antd-table-saveas-excel";

export default function UserManagement() {
    const [currentPage, setCurrentPage] = useState(1);
    const { data, status } = useSelector(state => state.user);
    const [search, setSearch] = useState('');
    const [list, setList] = useState(data);
    const dispatch = useDispatch();
    const header = useUserManagementHeader()

    useEffect(() => {
        if (status !== "success") {
            dispatch(fetchUserManagementThunk());
        }

        return () => dispatch(resetValue())
    }, [])

    useEffect(() => {
        if (!search) return setList(data);


        const array = [
            ...data.map(item => {
                if (item.firstname.toLowerCase().includes(search.toLowerCase()) || item.lastname.toLowerCase().includes(search.toLowerCase())) {
                    return item
                }
            })
        ].filter(Boolean)
        setList(array);

    }, [data, search])

    const handleSaveExcel = () => {
        const excel = new Excel();
        excel
            .addSheet("Users")
            .addColumns([
                {
                    title: "First Name",
                    dataIndex: "firstname",
                    key: "firstname"
                },
                {
                    title: "Last Name",
                    dataIndex: "lastname",
                    key: "lastname"
                },
                {
                    title: "Email",
                    dataIndex: "email",
                    key: "email"
                },
                {
                    title: "Phone No",
                    dataIndex: "mobile_no",
                    key: "mobile_no"
                }
            ])
            .addDataSource(list, {
                str2Percent: true
            })
            .saveAs("Users.xlsx");
    }

    return (
        <ContentContainer>
            <div className={styles.userManagement}>
                <SearchBar value={search} onChange={(value) => setSearch(value)} />
                <TableLayout
                    isLoading={(status == 'pending')}
                    data={list}
                    headers={header}
                    pageNo={currentPage}
                    handlePageChange={(page) => setCurrentPage(page)}
                />
                {(status === 'success') ? <div className={styles.toolContainer}>
                    <button onClick={handleSaveExcel} className={styles.excel}>Excel</button>

                    <p className={styles.recordCount}>
                        {`Showing ${(currentPage - 1) * 8 + 1} to ${(currentPage - 1) * 8 + 8} of ${list.length} entries`}
                    </p>
                </div> : ""}
            </div>
        </ContentContainer>
    )
}