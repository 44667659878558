import React from "react";

import styles from "./styles/Search.module.scss";
import { Input } from "antd";

export default function SearchBar({ value, onChange = () => { }, hasDivider = true }) {
    return (
        <div className={`${styles.searchContainer} ${hasDivider ? styles.divider : ""}`}>
            <p className={styles.title}>Search :</p>
            <Input size="small" type="text" value={value} onChange={(e) => onChange(e.target.value)} className={styles.input} />
        </div>
    )
}