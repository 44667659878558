import { Axios } from "../hooks/useAxiosInstance";

export const Notification = (function () {

    // const { api_token } = useSelector(state => state.profile);

    const apis = () => ({
        get: async (api_token) => await fetchRecord(api_token),
        create: async (data, api_token) => await createRecord(data, api_token),
        delete: async (id, api_token) => await deleteRecord(id, api_token),
    })

    async function fetchRecord(api_token) {
        const url = `admin/notifications`;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.get(url, option);
    }
    async function createRecord(data, api_token) {
        const url = `admin/notifications`;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.post(url, data, option);
    }

    async function deleteRecord(id, api_token) {
        const url = `admin/notifications/${id}`;
        const option = {
            headers: {
                'Authorization': api_token,
                'Content-Type': 'application/json'
            }
        }

        return await Axios.delete(url, option);
    }

    return apis();

})()
