import { configureStore } from '@reduxjs/toolkit';


import defaultReducer from './features/DefaultReducer';
import ProfileReducer from './features/ProfileReducer';
import SearchReducer from './features/SearchReducer';
import StaticTextReducer from './features/StaticTextReducer';
import UserManagementReducer from './features/UserManagementReducer';
import MenuReducer from './features/MenuReducer';
import NotificationReducer from './features/NotificationReducer';
import LookupReducer from './features/LookupReducer';
import GameReducer from './features/GameReducer';
import LeaderboardReducer from './features/LeaderboardReducer';
import MapReducer from './features/MapReducer';

export const store = configureStore({
  reducer: {
    default: defaultReducer,
    profile: ProfileReducer,
    search: SearchReducer,
    staticText: StaticTextReducer,
    user: UserManagementReducer,
    menu: MenuReducer,
    notification: NotificationReducer,
    lookups: LookupReducer,
    game: GameReducer,
    leaderboard: LeaderboardReducer,
    map: MapReducer,
  },
})