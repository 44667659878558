import React from "react";
import styles from "./ProtectedLayout.module.scss";


import { GiHamburgerMenu } from "react-icons/gi";
import { AiOutlineSearch } from "react-icons/ai";

import { useSelector, useDispatch } from "react-redux";

import { setValue } from "../../features/SearchReducer";


export default function Navbar({ isSearchBar, handleToggle, title }) {

    const { search } = useSelector(state => state.search);
    const { image, firstname } = useSelector(state => state.profile.data.user)
    const dispatch = useDispatch();



    return (
        <div className={styles.Navbar}>
            <div className={styles.titleContainer}>
                <span onClick={handleToggle} className={styles.toggleButton}><GiHamburgerMenu /></span>
                {/* <h3 className={styles.title}>{title}</h3> */}
            </div>

            <div className={styles.profileContainer}>
                <div className={styles.image}>
                    <img src={image} alt="User Image" />
                </div>
                <div className={styles.userinfo}>
                    <p className={styles.name}>{firstname}</p>
                    <p className={styles.type}>Super Admin</p>
                </div>

            </div>
            {isSearchBar ?
                <div className={styles.search}>
                    <input type="text" placeholder="Search" value={search} onChange={(e) => dispatch(setValue(e.target.value))} />
                    <div className={styles.searchButton}>
                        <AiOutlineSearch />

                    </div>

                </div>
                : ""}

        </div >
    )
}