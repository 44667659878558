import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { Menu } from '../Api/Menu';
import { Toast } from '../hooks/useToast';


export const fetchMenuThunk = createAsyncThunk(
    'menu/fetch',
    async (_, { getState }) => {
        try {
            const { api_token } = getState().profile.data;
            const response = await Menu.get(api_token);
            return response.data
        } catch (err) {
            Toast(err.message, 'error', false)
            return Promise.reject(err);
        }
    }
)

export const createMenuThunk = createAsyncThunk(
    'menu/create',
    async ({ data, handleModalClose = () => { }, setIsSubmitted }, { getState }) => {
        Toast("Creating menu", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const body = new FormData();
            body.append('price', data.price)
            body.append('name', data.item_name)
            body.append('categoryId', data.category_id)
            body.append('image', data.item_image)
            const response = await Menu.create(body, api_token);
            Toast("Menu created successfully", "success", false);
            handleModalClose()
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)
export const updateMenuThunk = createAsyncThunk(
    'menu/update',
    async ({ data, handleModalClose = () => { }, setIsSubmitted }, { getState }) => {
        Toast("Updating menu", "loading", true);
        try {
            const { api_token } = getState().profile.data;
            const body = new FormData();
            body.append('price', data.price)
            body.append('name', data.item_name)
            body.append('categoryId', data.category_id)
            body.append('image', data.item_image)
            const response = await Menu.update(data.id, body, api_token);
            Toast("Menu updated successfully", "success", false);
            handleModalClose()
            return response.data

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
        finally {
            setIsSubmitted(false)
        }
    }
)


export const deleteMenuThunk = createAsyncThunk(
    'menu/delete',
    async (id, { getState }) => {
        try {
            Toast("Deleting menu", 'loading', true);
            const { api_token } = getState().profile.data;
            const response = await Menu.delete(id, api_token);
            Toast("Menu deleted successfully!.", 'success', false);
            return id

        } catch (err) {
            Toast(err.message, "error", false);
            return Promise.reject(err)
        }
    }
)



const initialState = {
    status: "pending",
    data: [],
}

export const menuSlice = createSlice({
    name: 'menu',
    initialState,
    reducers: {
        resetState: () => {
            return initialState
        },
        setStatus: (state, actions) => {
            return {
                ...state,
                ...actions.payload
            }
        },

    },
    extraReducers: {
        [fetchMenuThunk.fulfilled]: (state, action) => {
            state.data = action.payload
            state.status = "success"
        },
        [fetchMenuThunk.pending]: (state, action) => {
            state.status = "pending"
        },
        [fetchMenuThunk.rejected]: (state, action) => {
            state.status = "error"
        },
        [createMenuThunk.fulfilled]: (state, action) => {
            state.data = [
                action.payload,
                ...state.data
            ]
            state.status = "success"
        },
        [createMenuThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [createMenuThunk.rejected]: (state, action) => {
            state.status = "success"
        },
        [updateMenuThunk.fulfilled]: (state, action) => {
            state.data = state.data.map(item => {
                if (item.id == action.payload.id) return action.payload
                return item;
            })
            state.status = "success"
        },
        [updateMenuThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [updateMenuThunk.rejected]: (state, action) => {
            state.status = "success"
        },

        [deleteMenuThunk.fulfilled]: (state, action) => {
            state.data = state.data.filter(item => item.id != action.payload)
            state.status = "success"
        },
        [deleteMenuThunk.pending]: (state, action) => {
            state.status = "success"
        },
        [deleteMenuThunk.rejected]: (state, action) => {
            state.status = "success"
        },
    }

})


// Action creators are generated for each case reducer function
export const { setStatus, resetState } = menuSlice.actions

export default menuSlice.reducer