import React from "react";

import "./TableLayout.css";

import { Table } from "antd";

import { HiOutlineFolder } from "react-icons/hi"

export default function TableLayout({ headers, data, isLoading, pageNo, handlePageChange }) {

    const emptyContainer = <div className={'ant-empty-description'}>
        <span className='icon'><HiOutlineFolder /></span>
        <h1>No Data Found</h1>
    </div>

    return (

        <Table
            tableLayout="auto"
            loading={isLoading}
            locale={{ emptyText: emptyContainer }}
            columns={headers}
            dataSource={data}
            pagination={{
                pageSize: 8,
                position: ["right"],
                className: "pagination",
                current: pageNo,
                onChange: (page) => handlePageChange(page)
            }}
            className={"table"}
            rowClassName={(record, index) => index % 2 === 0 ? 'row table-row-light' : 'row table-row-dark'}
        />

    )
}